import { ChainId } from "@pancakeswap/chains";
import { Address } from "wagmi";

export const WBETH: Record<number, Address> = {
  // [ChainId.ETHEREUM]: '0xa2E3356610840701BDf5611a53974510Ae27E2e1',
  [ChainId.SWAN_PROXIMA]: "0xa2E3356610840701BDf5611a53974510Ae27E2e1",
  // [ChainId.GOERLI]: '0xE7bCB9e341D546b66a46298f4893f5650a56e99E',
};

export const SNBNB: Record<number, Address> = {
  [ChainId.SWAN_PROXIMA]: "0x1adB950d8bB3dA4bE104211D5AB038628e477fE6",
};

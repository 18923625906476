import { ChainId } from "@pancakeswap/chains";
import { Currency, NATIVE, Token } from "@pancakeswap/sdk";
// import { ethereumTokens } from "@pancakeswap/tokens";
import memoize from "lodash/memoize";
import { getAddress } from "viem";

const mapping: { [key: number]: string } = {
  [ChainId.SWAN_PROXIMA]: "swanSaturn",
};

export const getTokenLogoURL = memoize(
  (token?: Token) => {
    if (token && mapping[token.chainId]) {
      // return `https://assets-cdn.trustwallet.com/blockchains/${mapping[token.chainId]}/assets/${getAddress(
      //   token.address,
      // )}/logo.png`;
      return `https://raw.githubusercontent.com/Mie-Protocol/token-list/main/data/${token.symbol.toUpperCase()}/logo.png`;
    }
    return null;
  },
  (t) => `${t?.chainId}#${t?.address}`,
);

export const getTokenLogoURLByAddress = memoize(
  (address?: string, chainId?: number) => {
    if (address && chainId && mapping[chainId]) {
      return `https://assets-cdn.trustwallet.com/blockchains/${mapping[chainId]}/assets/${getAddress(
        address,
      )}/logo.png`;
    }
    return null;
  },
  (address, chainId) => `${chainId}#${address}`,
);

const chainName: { [key: number]: string } = {
  [ChainId.SWAN_PROXIMA]: "swan",
};

// TODO: move to utils or token-list
export const getTokenListBaseURL = (chainId: number) => `https://tokens.mieswap.xyz/images/${chainName[chainId]}`;

export const getTokenListTokenUrl = (token: Token) =>
  Object.keys(chainName).includes(String(token.chainId))
    ? `https://tokens.mieswap.xyz/images/${chainName[token.chainId]}/
      ${token.address}.png`
    : null;

const commonCurrencySymbols = [
  // ethereumTokens.usdt,
  // ethereumTokens.usdc,
  // bscTokens.cake,
  // ethereumTokens.wbtc,
  // ethereumTokens.weth,
  NATIVE[ChainId.SWAN_PROXIMA],
  // bscTokens.busd,
  // ethereumTokens.dai,
].map(({ symbol }) => symbol);

export const getCommonCurrencyUrl = memoize(
  (currency?: Currency): string | undefined => getCommonCurrencyUrlBySymbol(currency?.symbol),
  (currency?: Currency) => `logoUrls#${currency?.chainId}#${currency?.symbol}`,
);

export const getCommonCurrencyUrlBySymbol = memoize(
  (symbol?: string): string | undefined =>
    //@ts-ignore
    symbol && commonCurrencySymbols.includes(symbol)
      ? `https://tokens.mieswap.xyz/images/symbol/${symbol.toLocaleLowerCase()}.png`
      : undefined,
  (symbol?: string) => `logoUrls#symbol#${symbol}`,
);

type GetLogoUrlsOptions = {
  useTrustWallet?: boolean;
};

export const getCurrencyLogoUrls = memoize(
  (currency: Currency | undefined, { useTrustWallet = true }: GetLogoUrlsOptions = {}): string[] => {
    const trustWalletLogo = getTokenLogoURL(currency?.wrapped);
    const logoUrl = currency ? getTokenListTokenUrl(currency.wrapped) : null;
    return [getCommonCurrencyUrl(currency), useTrustWallet ? trustWalletLogo : undefined, logoUrl].filter(
      (url): url is string => !!url,
    );
  },
  (currency: Currency | undefined, options?: GetLogoUrlsOptions) =>
    `logoUrls#${currency?.chainId}#${currency?.wrapped?.address}#${options ? JSON.stringify(options) : ""}`,
);

import { ChainId } from "@pancakeswap/chains";
import { perpLangMap } from "utils/getPerpetualLanguageCode";
import { perpTheme } from "utils/getPerpetualTheme";

interface GetPerpetualUrlProps {
  chainId: ChainId | undefined;
  languageCode: string | undefined;
  isDark: boolean;
}
// TODO
const mapPerpChain = (chainId: ChainId): string => {
  switch (chainId) {
    default:
      return "eth";
  }
};

const supportV2Chains: ChainId[] = [ChainId.SWAN_PROXIMA];

export const getPerpetualUrl = ({
  chainId,
  languageCode,
  isDark,
}: GetPerpetualUrlProps) => {
  if (!chainId || !languageCode) {
    return "https://perp.mieswap.xyz/en/futures/v2/BTCUSD";
  }

  const perpChain = mapPerpChain(chainId);
  const version = supportV2Chains.includes(chainId) ? "v2/" : "";
  return `https://perp.mieswap.xyz/${perpLangMap(
    languageCode
  )}/futures/${version}BTCUSD?theme=${perpTheme(isDark)}&chain=${perpChain}`;
};

import { ChainId, chainNames } from "@pancakeswap/chains";
import memoize from "lodash/memoize";
import { defineChain } from "viem";
import { bsc, Chain } from "wagmi/chains";

export const CHAIN_QUERY_NAME = chainNames;

const CHAIN_QUERY_NAME_TO_ID = Object.entries(CHAIN_QUERY_NAME).reduce(
  (acc, [chainId, chainName]) => {
    return {
      [chainName.toLowerCase()]: chainId as unknown as ChainId,
      ...acc,
    };
  },
  {} as Record<string, ChainId>
);

export const getChainId = memoize((chainName: string) => {
  if (!chainName) return undefined;
  return CHAIN_QUERY_NAME_TO_ID[chainName.toLowerCase()]
    ? +CHAIN_QUERY_NAME_TO_ID[chainName.toLowerCase()]
    : undefined;
});

/**
 * Controls some L2 specific behavior, e.g. slippage tolerance, special UI behavior.
 * The expectation is that all of these networks have immediate transaction confirmation.
 */
export const L2_CHAIN_IDS: ChainId[] = [ChainId.SWAN_PROXIMA];
const swanSaturn = defineChain({
  id: 20241133,
  name: "Swan Proxima",
  nativeCurrency: {
    decimals: 18,
    name: "Ether",
    symbol: "ETH",
  },
  rpcUrls: {
    default: {
      http: ["https://rpc-proxima.swanchain.io"],
      webSocket: ["wss://rpc.zora.energy"],
    },
    public: {
      http: ["https://rpc-proxima.swanchain.io"],
      webSocket: [],
    },
  },
  blockExplorers: {
    default: {
      name: "Explorer",
      url: "https://proxima-explorer.swanchain.io/",
    },
  },
  contracts: {
    multicall3: {
      address: "0xcAa1e7F91779EA0E6Ed4dF48e9Cc02278e2d1041",
      blockCreated: 10297986,
    },
  },
  network: "",
});

export const CHAINS: Chain[] = [
  swanSaturn,
  // bsc,
  // mainnet,
  // bscTestnet,
  // goerli,
  // polygonZkEvm,
  // polygonZkEvmTestnet,
  // zkSync,
  // zkSyncTestnet,
  // arbitrum,
  // arbitrumGoerli,
  // linea,
  // lineaTestnet,
  // arbitrumGoerli,
  // arbitrum,
  // base,
  // baseGoerli,
  // opBNB,
  // opBNBTestnet,
  // scrollSepolia,
];
